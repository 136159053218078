import { injectGlobal } from "@emotion/css";
import classesApplier from "../classes_applier";
import cursor from "../assets/mouse-pointer.svg";
import tts from "../tts";
import trans from "../../../assets/helpers/translator";

class CursorBlockAction {
    handlesAction() {
        return "block-toggle#CursorBlock";
    }

    isPersistent() {
        return true;
    }

    perform(details) {
        const enable = details.enabled;

        if (enable === false) {
            classesApplier.remove("html", true, "wbb-cursor");
            return;
        }

        classesApplier.apply("html", true, "wbb-cursor");

        tts.speak(trans("Bigger cursor activated"));
    }

    registerCustomStyles() {
        // language=scss
        injectGlobal`
            .wbb-cursor,
            .wbb-cursor *,
            .wbb-cursor > * {
                cursor: url("${cursor}"), auto !important;
            }
        `;
    }
}

export default CursorBlockAction;
