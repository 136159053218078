import { startStimulusApp } from "@symfony/stimulus-bridge";
import Dropdown from "stimulus-dropdown";

// Registers Stimulus controllers from controllers.json and in the controllers/ directory
export const app = startStimulusApp(
  require.context(
    "@symfony/stimulus-bridge/lazy-controller-loader!./controllers",
    true,
    /\.(j|t)sx?$/
  )
);

app.register('dropdown', Dropdown)
