// TODO: Move Sentry to separate file
import * as Sentry from "@sentry/browser";
import { BrowserTracing } from "@sentry/tracing";

Sentry.init({
    dsn: "https://3b37acc9c8d0426c8d598fe7a4bedf32@o1153803.ingest.sentry.io/6233490",
    integrations: [new BrowserTracing()],
    tracesSampleRate: 1.0,
});

import "./images/logo_icons.png";

import "./styles/app.scss";
import "./styles/widget.scss";
import "../script/src/widget.scss";

import "./bootstrap";

import "../node_modules/line-awesome/dist/line-awesome/css/line-awesome.min.css";

const routes = require('../public/js/fos_js_routes.json');
import Routing from '../public/bundles/fosjsrouting/js/router.min.js';
Routing.setRoutingData(routes);

import './turbo/turbo-helper';
