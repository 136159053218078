import { useDebounce, ApplicationController } from 'stimulus-use'
import { Controller } from "@hotwired/stimulus";
import axios from "axios";
import trans from "../helpers/translator";
import getLocale from "../helpers/translator";

export default class extends Controller {
    static targets = [ "searchInput", "content", "searchIcon", "loadingIcon" ];
    static debounces = [ "search" ];

    connect() {
        useDebounce(this, { wait: 500 });
    }

    submit(event) {
        event.preventDefault();
        this.search();
    }

    search() {
        this.contentTarget.innerHTML = "";
        this.searchInputTarget.classList.remove("!rounded-b-none");
        this.searchIconTarget.classList.add("hidden");
        this.loadingIconTarget.classList.remove("hidden");

        const searchTerm = this.searchInputTarget.value;

        if (searchTerm.length === 0) {
            this.searchInputTarget.classList.remove("!rounded-b-none");
            this.searchIconTarget.classList.remove("hidden");
            this.loadingIconTarget.classList.add("hidden");
            return;
        }

        // REFACTOR: Proper way to use locale
        axios.get(`https://${getLocale() === 'cs' ? 'cs' : 'sk'}.wikipedia.org/w/api.php?action=query&format=json&utf8=&explaintext=&extracts=&exintro=&generator=prefixsearch&prop=pageprops|extracts|exintro|extracts|description&redirects=&gpssearch=${searchTerm}&gpslimit=3&origin=*`)
            .then(response => {
                let results = response.data?.query?.pages;

                if (!results) {
                    this.contentTarget.innerHTML += `
                        <div class="mx-3 py-3 px-2 border-b border-x border-gray-400 last:rounded-b text-center">
                            ${trans("No results found")}
                        </div>
                    `;
                    this.searchInputTarget.classList.add("!rounded-b-none");
                    this.searchIconTarget.classList.remove("hidden");
                    this.loadingIconTarget.classList.add("hidden");
                    return;
                }

                let index = 1;
                for (let resultId in results) {
                    const result = results[resultId];
                    const title = result.title;

                    let description = result.description;
                    if (!description) {
                        description = result.extract;
                        if (description.length > 200) {
                            description = description.substring(0, 200) + "...";
                        }
                    }

                    // REFACTOR: Proper way to use locale
                    const html = `
                        <div class="mx-3 py-2 px-2 border-b border-x border-gray-400 last:rounded-b">
                            <div class="font-bold">
                                <span>${index}.</span> ${title}
                            </div>
                            <div class="py-1">${description || "-"}</div>
                            <a 
                                href="https://${getLocale() === 'cs' ? 'cs' : 'sk'}.wikipedia.org/wiki/${title.replaceAll(" ", "_")}" 
                                target="_blank" 
                                tabindex="0"
                            >
                                ${trans("Learn more on Wikipedia")}
                            </a>
                        </div>
                    `;

                    this.contentTarget.innerHTML += html;
                    this.searchInputTarget.classList.add("!rounded-b-none");
                    this.searchIconTarget.classList.remove("hidden");
                    this.loadingIconTarget.classList.add("hidden");

                    index++;
                }
            })
            .catch(error => {
                console.log(error);
            });
    }
}
