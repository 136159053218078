
export default {
  "Log out": () => "Odhlásit se",
  Register: () => "Registrace",
  "Create an account and get 14 days for free!": () => "Vytvořte si účet a získejte 14 dní zdarma!",
  "I agree to the <a target=\"_blank\" href=\"{url}\">terms of service</a>": (d) => "Souhlasím s <a target=\"_blank\" href=\"" + d.url + "\">podmínkami služby</a>",
  "Register now!": () => "Zaregistrovat!",
  "Already have an account? Log in.": () => "Už máte účet? Přihlašte se.",
  "Accessibility menu": () => "Přístupnost",
  "Page structure": () => "Struktura stránky",
  "Reset all": () => "Resetovat vše",
  Settings: () => "Nastavení",
  "First setup": () => "Prvotní nastavení",
  Login: () => "Přihlášení",
  "Do you need to create a new account?": () => "Potřebujete vytvořit nový účet?",
  Email: () => "Email",
  Password: () => "Heslo",
  "Remember me": () => "Zapamatovat",
  "Log in!": () => "Přihlásit se!",
  "Forgot your password?": () => "Zapomenuté heslo?",
  "Add new website": () => "Přidat nový web",
  Provides: () => "Poskytuje",
  "Save details": () => "Uložit údaje",
  Websites: () => "Weby",
  Account: () => "Účet",
  Invoices: () => "Faktury",
  "Billing details": () => "Fakturační údaje",
  Domain: () => "Doména",
  "Open in new tab": () => "Otevřít v nové záložce",
  "Added at": () => "Datum přidání",
  "Last activity": () => "Poslední aktivita",
  Edit: () => "Upravit",
  "Search...": () => "Hledat...",
  "Sort column ascending": () => "Seřadit sloupec vzestupně",
  "Sort column descending": () => "Seřadit sloupec sestupně",
  Previous: () => "Předchozí",
  Next: () => "Další",
  "Page {page} of {pages}": (d) => "Strana " + d.page + " z " + d.pages,
  "Page {page}": (d) => "Strana " + d.page,
  Showing: () => "Zobrazuji",
  of: () => "z",
  to: () => "až",
  results: () => "výsledků",
  "Loading...": () => "Načítání...",
  "No matching records found": () => "Nebyly nalezeny žádné záznamy",
  "An error happened while fetching the data": () => "Při načítání dat došlo k chybě",
  "Reset your password": () => "Obnovit heslo",
  "Password reset": () => "Obnovení hesla",
  "After submitting the form we will send you a link to reset your password": () => "Po odeslání formuláře Vám zašleme odkaz pro resetování hesla",
  "Go back to login page": () => "Přejít zpět na přihlášení",
  "Password reset email sent": () => "Email pro obnovení hesla odeslán",
  "If an account matching your email exists, then an email was just sent that contains a link that you can use to reset your password.": () => "Pokud existuje účet odpovídající zadanému e-mailu, byl právě odeslán e-mail s odkazem pro obnovení hesla.",
  "This link will expire in": () => "Tento odkaz vyprší za",
  "If you don't receive an email please check your spam folder or <a href=\"{password_reset_link}\">try again</a>.": (d) => "Pokud e-mail pro obnovu hesla neobdržíte, zkontrolujte prosím složku se spamem nebo <a href=\"" + d.password_reset_link + "\">to zkuste znovu</a>.",
  "Please enter your new password": () => "Zadejte prosím nové heslo",
  "Send password reset email": () => "Odeslat email pro obnovu hesla",
  "Reset password": () => "Obnovit heslo",
  "You have already requested a reset password email. Please check your email or try again soon.": () => "Již jste si vyžádali e-mail s žádostí o obnovení hesla. Zkontrolujte prosím svůj e-mail nebo to později zkuste znovu.",
  "New password": () => "Nové heslo",
  "Repeat password": () => "Nové heslo znovu",
  "The password fields must match": () => "Hesla se musí shodovat",
  "Company dic": () => "DIČ",
  "Company country": () => "Země",
  "Company zip": () => "PSČ",
  "Company ic": () => "IČ",
  "Company city": () => "Město",
  "Company street": () => "Ulice",
  "Firstname and Surname or Company Name": () => "Jméno a příjmení / název společnosti",
  "Your changes were saved": () => "Vaše změny byly uloženy",
  "Account settings": () => "Nastavení účtu",
  "Save settings": () => "Uložit nastavení",
  "Your password has been changed successfully": () => "Vaše heslo bylo úspěšně změněno",
  "Change password": () => "Změnit heslo",
  "Current password": () => "Současné heslo",
  "Password change": () => "Změna hesla",
  Firstname: () => "Jméno",
  Surname: () => "Příjmení",
  Save: () => "Uložit",
  "Position on website": () => "Pozice na webu",
  Theme: () => "Obarvení",
  "Bottom right": () => "Vpravo dole",
  "Bottom left": () => "Vlevo dole",
  "Top right": () => "Vpravo nahoře",
  "Top left": () => "Vlevo nahoře",
  "Widget settings": () => "Nastavení widgetu",
  Revert: () => "Vrátit zpět",
  Copy: () => "Kopírovat",
  Continue: () => "Pokračovat",
  Copied: () => "Zkopírováno",
  "Opener Icon": () => "Ikonka otevírače",
  Person: () => "Osoba",
  Blind: () => "Nevidomý",
  Deaf: () => "Hluchý",
  "Sign language": () => "Znaková řeč",
  Wheelchair: () => "Invalidní vozík",
  "Search dictionary...": () => "Hledat ve slovníku...",
  "Big Cursor": () => "Velký kurzor",
  Contrast: () => "Kontrast",
  "No results found": () => "Nebyly nalezeny žádné výsledky",
  "Learn more on Wikipedia": () => "Zjistit více na Wikipedii",
  "Stop animations": () => "Zastavit animace",
  "Font size": () => "Velikost písma",
  "Line height": () => "Výška řádku",
  "Text spacing": () => "Rozsazení textu",
  Saturation: () => "Barevnost",
  Close: () => "Zavřít",
  "Close page structure modal": () => "Zavřít okno se strukturou stránky",
  Company: () => "Společnost",
  Administration: () => "Administrace",
  Users: () => "Uživatelé",
  "Highlight links": () => "Zvýraznit odkazy",
  Focus: () => "Soustředění",
  Impersonate: () => "Impersonovat",
  "Exit impersonation": () => "Zrušit impersonaci",
  "Screen reader": () => "Čtečka",
  "Click to help with orientation on this page": () => "Klikněte pro pomoc s orientací na této stránce",
  "Hide tooltip describing widget functionality": () => "Skrýt titulek popisující funkčnost widgetu",
  "Open help to orient yourself on this website": () => "Otevřít pomocníka pro orientaci na stránce",
  Views: () => "Zobrazení",
  "Preview PDF OCR": () => "Náhled PDF OCR",
  "Original PDF": () => "Původní PDF",
  "Modified PDF": () => "Zmodifikované PDF",
  "PDF file to convert": () => "PDF soubor pro převod",
  "Please upload a valid PDF document": () => "Prosím nahrajte validní PDF dokument",
  "Upload and show preview": () => "Nahrát a zobrazit náhled",
  "Language of PDF": () => "Jazyk PDF",
  "Textual content": () => "Textový obsah",
  "Setup plan": () => "Nastavit plán",
  "Yearly payment": () => "Roční platba",
  "Monthly payment": () => "Měsíční platba",
  "2 months free": () => "2 měsíce zdarma",
  "Drag and drop or browse": () => "Přetáhněte a pusťte, nebo procházejte",
  "Most favourite": () => "Nejoblíbenější",
  "Save and continue to plan change setup": () => "Uložit a pokračovat v nastavení plánu",
  "Your plan has been changed successfully!": () => "Váš plán byl úspěšně změněn!",
  "Please fill your billing details first.": () => "Prosím nejprve vyplňte své fakturační údaje.",
  "Change plan": () => "Změnit plán",
  "Current plan": () => "Zvolený plán",
  month: () => "měsíc",
  "Unlimited domains": () => "Neomezeně domén",
  "No restrictions on features": () => "Bez omezení funkcí",
  "Priority support": () => "Prioritní podpora",
  Language: () => "Jazyk",
  "Trial period has expired. Your Widget is paused. Please, choose a paid plan to continue using app.": () => "Zkušební doba vypršela. Váš Widget je pozastaven. Chcete-li aplikaci používat i nadále, zvolte si prosím placený plán.",
  Yes: () => "Ano",
  No: () => "Ne",
  Yearly: () => "Roční",
  Monthly: () => "Měsíční",
  "How to add widget to your website": () => "Jak vložit widget Web bez bariéry na Vaše webové stránky",
  "Log in to the administration of your website": () => "Přihlaste se do administrace Vašeho webu",
  "Copy this code and paste it into the header of your website (just before {head}) where you want to display the widget": (d) => "Zkopírujte tento kód a vložte jej do hlavičky Vašeho webu (těsně před " + d.head + "), na kterém chcete widget zobrazit",
  "Save the changes in your site's administration and go to your website, where you should already see the widget icon": () => "Změny v administraci Vašeho webu uložte a přejděte na svou webovou stránku, kde byste již měli vidět ikonu widgetu",
  "Now click Continue on this page": () => "Nyní klikněte na tlačítko Pokračovat na této stránce",
  "Do you have problems with inserting the code on your website or does the widget not appear on the website after inserting the code? <a href=\"{contact_us_url}\" target=\"_blank\">Contact us</a>, we will be happy to help you with the installation.": (d) => "Máte problémy s nasazením kódu na Váš web nebo se po vložení kódu widget na webu nezobrazuje? <a href=\"" + d.contact_us_url + "\" target=\"_blank\">Kontaktujte nás</a>, rádi Vám s nasazením pomůžeme.",
  "Show helper tooltip": () => "Zobrazit tooltip nápovědu na otevírači",
  "Plan upgrade review": () => "Shrnutí před vylepšením plánu",
  "Continue to payment": () => "Pokračovat k platbě",
  "E-mail": () => "E-mail",
  "Last login": () => "Poslední přihlášení",
  Trial: () => "Trial",
  Paying: () => "Platící",
  Plan: () => "Plán",
  Period: () => "Období",
  Roles: () => "Role",
  "Phone number": () => "Telefonní číslo",
  "Country, language": () => "Země, jazyk",
  "Company name": () => "Název společnosti",
  "City, ZIP": () => "Město, PSČ",
  IC: () => "IČ",
  DIC: () => "DIČ",
  "An error occurred while processing your payment. Please try again, or contact support.": () => "Při zpracovávání Vaší platby došlo k chybě. Zkuste to prosím znovu, případně kontaktujte podporu.",
  "Number of views": () => "Počet zobrazení",
  "Views trend (1M)": () => "Trend zobrazení (1M)"
}