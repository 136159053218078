const elementUtils = () => ({
    customTrim(text) {
        return text.trim().replace(/(\n|\r\n)/g, "").replace(/\s+/g, " ");
    },

    getElementsWithText(fromElement = document) {
        const allInsideElements = fromElement.querySelectorAll("*");

        const elementsWithText = [];
        allInsideElements.forEach(element => {
            if (element.nodeType !== Node.ELEMENT_NODE) {
                return;
            }

            if (["head", "script", "style", "body"].includes(element.nodeName.toLowerCase())) {
                return;
            }

            if (element.textContent.trim().length === 0) {
                return;
            }

            elementsWithText.push(element);
        });

        return elementsWithText;
    }
});

export default elementUtils();
