import { Controller } from "@hotwired/stimulus";
import { useClickOutside } from "stimulus-use";
import sendAction from "../helpers/actions_sender";
import actionsPerformer from "../../script/src/actions_performer";
import log from "../helpers/logger";

export default class extends Controller {
    connect() {
        actionsPerformer.registerAllCustomStyles();
        this.handleMessagesFromParent();
        this.registerCursorPositionEvents();

        useClickOutside(this, {
            events: [
                "mousedown",
                "touchstart"
            ]
        });
    }

    clickOutside(event) {
        event.preventDefault();
        this.toggleWidget();
    }

    toggleWidget() {
        sendAction("toggle-widget");
    }

    resetAll() {
        sendAction("reset-all");
    }

    pageStructure() {
        sendAction("page-structure");
    }

    registerCursorPositionEvents() {
        document.removeEventListener("mousemove", this.sendCursorPosition);
        document.addEventListener("mousemove", this.sendCursorPosition);
    }

    sendCursorPosition(event) {
        sendAction("cursor-position", {
            x: event.x,
            y: event.y
        });
    }

    handleMessagesFromParent() {
        log.debug("Waiting for messages from window.");
        window.addEventListener("message", message => {
            const data = message.data;
            log.debug("Received data from parent window", message.data);

            const type = data.type;

            if (type === "add-class") {
                this.handleClassAdd(data);
            } else if (type === "remove-class") {
                this.handleClassRemoval(data);
            } else if (type === "saved-settings") {
                this.handleSavedSettings(data);
            }
        });
    }

    handleClassAdd(data) {
        const elementsSelector = data.elementsSelector;
        const classes = data.classes;

        document.querySelectorAll(elementsSelector).forEach(element => {
            element?.classList.add(...classes);
        });
    }

    handleClassRemoval(data) {
        const elementsSelector = data.elementsSelector;
        const classes = data.classes;

        document.querySelectorAll(elementsSelector).forEach(element => {
            element?.classList.remove(...classes);
        });
    }

    handleSavedSettings(data) {
        log.debug("Got settings for stylization", data.settings);

        Object.entries(data.settings).forEach(entry => {
            const [key, details] = entry;
            const block = key.split("#").pop();

            const blockElement = document.querySelector(`[data-block-type="${block}"]`);
            if (blockElement === null) {
                return;
            }

            log.debug("Stylizing saved settings", key, block, details);

            if (details?.enabled === true) {
                blockElement?.classList.add("active");
            }

            if (details?.level !== null) {
                blockElement.querySelector(".widget-block-levels").dataset.activeLevel = details.level;
                for (let level = 1; level <= details.level; level++) {
                    blockElement.querySelector(`[data-level="${level}"]`)?.classList.add("active");
                }
            }
        });
    }
}
