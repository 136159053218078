import { injectGlobal } from "@emotion/css";
import classesApplier from "../classes_applier";
import tts from "../tts";
import trans from "../../../assets/helpers/translator";

class StopAnimationsBlockAction {
    handlesAction() {
        return "block-toggle#StopAnimationsBlock";
    }

    isPersistent() {
        return true;
    }

    perform(details) {
        const enable = details.enabled;

        if (enable === false) {
            classesApplier.remove("html", true, "wbb-stop-animations");
            this.disableJQueryAnimations(false);
            return;
        }

        classesApplier.apply("html", true, "wbb-stop-animations");
        this.disableJQueryAnimations(true);

        tts.speak(trans("Animations stopped"));
    }

    registerCustomStyles() {
        // language=scss
        injectGlobal`
            .wbb-stop-animations,
            .wbb-stop-animations *,
            .wbb-stop-animations > * {
                transition-timing-function: step-end !important;
                transition-duration: 0s !important;
                animation-timing-function: step-end !important;
                animation-iteration-count: 1 !important;
                animation-duration: 0s !important;
            }
        `;
    }

    disableJQueryAnimations(disable) {
        if (typeof window.jQuery === "undefined") {
            return;
        }

        window.jQuery.fx.off = disable
    }
}

export default StopAnimationsBlockAction;
