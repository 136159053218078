import { Controller } from "@hotwired/stimulus";
import sendAction from "../helpers/actions_sender";
import hotkeys from 'hotkeys-js';

export default class extends Controller {
    static targets = [ "levels" ];

    onBlockClick(event) {
        event.preventDefault();

        const blockType = this.element.dataset.blockType;
        const maxLevel = this.element.querySelectorAll("[data-level]").length;

        if (maxLevel === 0) {
            this.element.classList.toggle("active");

            sendAction(`block-toggle#${blockType}`, {
                enabled: this.element.classList.contains("active")
            });

            return;
        }

        this.element.classList.add("active");

        let level = this.levelsTarget.dataset.activeLevel || 0;

        level++;

        if (level > maxLevel) {
            this.levelsTarget.dataset.activeLevel = 0;
            this.element.classList.remove("active");
            this.levelsTarget.querySelectorAll(`[data-level]`).forEach(item =>
                item.classList.remove("active")
            );
            sendAction(`block-toggle#${blockType}`, {
                enabled: false
            });
            return;
        }

        this.levelsTarget.querySelector(`[data-level="${level}"]`)?.classList.add("active");
        this.levelsTarget.dataset.activeLevel = level;

        sendAction(`block-toggle#${blockType}`, {
            level: level,
            enabled: true
        });
    }
}
