import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
    static targets = [ "select", "theme" ]

    connect() {
        this.hideCheckmarkOnThemeOptions();
        this.setCheckmarkOnSelectedTheme();
    }

    themeChange(event) {
        const selectElement = this.selectTarget;
        const themeTarget = event.currentTarget;
        const themeId = themeTarget.dataset.themeId;
        selectElement.value = themeId;

        this.reloadPreviews(themeId);
        this.hideCheckmarkOnThemeOptions();
        this.setCheckmarkOnSelectedTheme(themeId);

        themeTarget.querySelector('div').classList.remove("hidden");
    }

    setCheckmarkOnSelectedTheme(themeId = null) {
        if (themeId === null) {
            themeId = this.selectTarget.value;
        }

        document.querySelector(`[data-theme-id="${themeId}"] div`).classList.remove("hidden");
    }

    hideCheckmarkOnThemeOptions() {
        this.themeTargets.forEach((target) => {
            target.querySelector('div').classList.add("hidden");
        });
    }

    reloadPreviews(themeId) {
        let widgetPreviewFrame = document.querySelector("#widget");
        let widgetPreviewUrl = new URL(widgetPreviewFrame.src);
        widgetPreviewUrl.searchParams.set("themeId", themeId);
        widgetPreviewFrame.src = widgetPreviewUrl.toString();

        let openerPreviewFrame = document.querySelector("#opener");
        let openerPreviewUrl = new URL(openerPreviewFrame.src);
        openerPreviewUrl.searchParams.set("themeId", themeId);
        openerPreviewFrame.src = openerPreviewUrl.toString();
    }
}
