
export default {
  "Reader enabled": () => "Čítačka zapnutá",
  "Opening widget for better accessibility of this website": () => "Otváram widget pre lepšiu orientáciu na tomto webe",
  "{level}. level of contrast mode activated": (d) => d.level + ". stupeň kontrastného režimu bol aktivovaný",
  "Bigger cursor activated": () => "Väčší kurzor bol aktivovaný",
  "{level}. level of saturation mode activated": (d) => d.level + ". stupeň sýtosti bol aktivovaný",
  "Font size increased to {level}": (d) => "Veľkosť písma bola zväčšená na " + d.level,
  "Text spacing increased to {level}": (d) => "Rozostup písmen bol zväčšený na " + d.level,
  "Line height increased to {level}": (d) => "Výška riadku bola zväčšená na " + d.level,
  "Animations stopped": () => "Animácie boli zastavené",
  "Focus mode activated": () => "Režim sústredenia bol aktivovaný",
  "All links were highlighted": () => "Všetky odkazy boli zvýraznené",
  "Read this PDF": () => "Čítať toto PDF",
  "Stop PDF reading": () => "Zastaviť čítanie PDF"
}