const style = {
    prefix: "color: #ffffff; background-color: #2463eb; font-weight: bold; padding: 2px 4px;",
    text: "color: #000000; background-color: #ffffff; padding: 2px 4px;",
    errorPrefix: "color: #fee2e2; background-color: #971b1b; font-weight: bold; padding: 2px 4px;",
    errorText: "color: #7f1d1d; background-color: #fecaca; padding: 2px 4px;",
};

const log = () => ({
    isLoggingEnabled() {
        return localStorage.getItem("wbb-debug");
    },

    visible(...data) {
        console.info(`%c[WBB DEBUG]%c${[...data].join(" ")}`, style.prefix, style.text);
    },

    error(...data) {
        console.info(`%c[WBB ERROR]%c${[...data].join(" ")}`, style.errorPrefix, style.errorText);
    },

    debug(message, ...data) {
        if (!this.isLoggingEnabled()) {
            return;
        }

        console.info(`%c[WBB DEBUG]%c${message}`, style.prefix, style.text, ...data);
    },
});

export default log();
