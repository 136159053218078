import { injectGlobal } from "@emotion/css";
import elementUtils from "../element_utils";
import tts from "../tts";
import trans from "../../../assets/helpers/translator";

class LineHeightBlockAction {
    handlesAction() {
        return "block-toggle#LineHeightBlock";
    }

    isPersistent() {
        return true;
    }

    perform(details) {
        const enable = details.enabled;
        const level = details.level;

        elementUtils.getElementsWithText().forEach(element => {
            element.classList.remove("wbb-line-height-1", "wbb-line-height-2", "wbb-line-height-3");
        });

        if (enable === false) {
            return;
        }

        elementUtils.getElementsWithText().forEach(element => {
            element.classList.add(`wbb-line-height-${level}`);
        });

        tts.speak(trans("Line height increased to {level}", {level}));
    }

    registerCustomStyles() {
        // language=scss
        injectGlobal`
            .wbb-line-height-1 {
                line-height: 1.8em !important;
            }
            .wbb-line-height-2 {
                line-height: 2.1em !important;
            }
            .wbb-line-height-3 {
                line-height: 2.4em !important;
            }
        `;
    }
}

export default LineHeightBlockAction;
