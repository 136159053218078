import { injectGlobal } from "@emotion/css";
import classesApplier from "../classes_applier";
import tts from "../tts";
import trans from "../../../assets/helpers/translator";

class TextSizeBlockAction {
    handlesAction() {
        return "block-toggle#TextSizeBlock";
    }

    isPersistent() {
        return true;
    }

    perform(details) {
        const enable = details.enabled;
        const level = details.level;

        classesApplier.remove(
            "html",
            false,
            "wbb-font-size-1",
            "wbb-font-size-2",
            "wbb-font-size-3",
            "wbb-font-size-4",
        );

        if (enable === false) {
            return;
        }

        classesApplier.apply("html", false, `wbb-font-size-${level}`);

        tts.speak(trans("Font size increased to {level}", {level}));
    }

    registerCustomStyles() {
        // language=scss
        injectGlobal`
            .wbb-font-size-1 {
                zoom: 1.2 !important;
            }
            .wbb-font-size-2 {
                zoom: 1.3 !important;
            }
            .wbb-font-size-3 {
                zoom: 1.5 !important;
            }
            .wbb-font-size-4 {
                zoom: 1.6 !important;
            }
        `;
    }
}

export default TextSizeBlockAction;
