import log from "../../assets/helpers/logger";
import actionsPerformer from "./actions_performer";

const prefix = "wbb-";

const settingsManager = () => ({
    setup() {
        this.activateSavedSettings();
        this.postSavedSettingsToIframe();
    },

    activateSavedSettings() {
        log.debug("Activating saved settings...", this.getAll());

        Object.entries(this.getAll()).forEach(entry => {
            const [key, details] = entry;

            if (typeof details === "object") {
                details["savedInit"] = true;
            }

            actionsPerformer.getActionPerformer(key)?.perform(details);
        });
    },

    save(name, value) {
        localStorage.setItem(
            prefix + name,
            JSON.stringify(value)
        );
    },

    remove(name) {
        localStorage.removeItem(prefix + name);
    },

    get(name) {
        const item = localStorage.getItem(prefix + name);
        if (!item) {
            return null;
        }

        return JSON.parse(item);
    },

    getAll() {
        const settings = {};
        for (let i = 0; i < localStorage.length; i++) {
            const key = localStorage.key(i);
            if (key.startsWith(prefix)) {
                settings[key.substring(prefix.length)] = JSON.parse(localStorage.getItem(key));
            }
        }

        return settings;
    },

    clearAll() {
        Object.entries(this.getAll()).forEach(entry => {
            const [key, details] = entry;
            localStorage.removeItem(prefix + key);
        });
    },

    postSavedSettingsToIframe() {
        const iframe = document.querySelector("#widget-iframe");

        log.debug("Posting saved settings to iframe...", this.getAll());
        iframe.contentWindow.postMessage({
            type: "saved-settings",
            settings: this.getAll()
        }, "*");
    }
});

export default settingsManager();
