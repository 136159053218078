import { injectGlobal } from "@emotion/css";
import tts from "../tts";
import trans from "../../../assets/helpers/translator";

class FocusBlockAction {
    handlesAction() {
        return "block-toggle#FocusBlock";
    }

    isPersistent() {
        return true;
    }

    perform(details) {
        const enable = details.enabled;

        if (enable === false) {
            document.removeEventListener("mousemove", (event) => this.trackMouse(event));
            document.getElementById("wbb-focus-top").remove();
            document.getElementById("wbb-focus-bottom").remove();
            return;
        }

        document.body.insertAdjacentHTML("afterbegin", `
            <div id="wbb-focus-top"></div>
            <div id="wbb-focus-bottom"></div>
        `);

        document.addEventListener("mousemove", (event) => this.trackMouse(event));

        tts.speak(trans("Focus mode activated"));
    }

    registerCustomStyles() {
        // language=scss
        injectGlobal`
            #wbb-focus-top, 
            #wbb-focus-bottom {
              background-color: rgba(0,0,0,.5);
              height: 0;
              left: 0;
              margin: auto;
              pointer-events: none;
              position: fixed;
              right: 0;
              top: 0;
              width: 100%;
              z-index: 2147483647;
            }

            #wbb-focus-top {
              zoom: 1.041 !important;
              bottom: auto;
              top: 0;
            }

            #wbb-focus-bottom {
              zoom: 1.041 !important;
              bottom: 0;
              top: auto;
            }
        `;
    }

    trackMouse(event) {
        this.setFocusPosition(event.y);
    }

    setFocusPosition(y) {
        let focusTopElement = document.getElementById("wbb-focus-top");
        let focusBottomElement = document.getElementById("wbb-focus-bottom");

        if (focusTopElement === null || focusBottomElement === null) {
            return;
        }

        focusTopElement.style.height = y - 84 + "px";
        focusBottomElement.style.height = window.innerHeight - y - 196 + "px";
    }
}

export default FocusBlockAction;
