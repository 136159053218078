const packageJson = require("../package.json");

const configGetter = () => ({
    get(name) {
        const env = process.env.NODE_ENV;

        return packageJson.config[env][name] || null;
    }
});

export default configGetter();
