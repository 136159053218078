import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
    static targets = [ "select" ];

    iconChange() {
        const selectedIcon = this.selectTarget.value;
        let openerPreviewFrame = document.querySelector("#opener");
        let openerPreviewUrl = new URL(openerPreviewFrame.src);
        openerPreviewUrl.searchParams.set("icon", selectedIcon);
        openerPreviewFrame.src = openerPreviewUrl.toString();
    }
}
