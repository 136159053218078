const TurboHelper = class {
    constructor() {
        this.destroyGridJsTablesBeforeCache();
    }

    destroyGridJsTablesBeforeCache() {
        document.addEventListener('turbo:before-cache', () => {
            document.querySelector('.gridjs.gridjs-container')?.remove();
        });
    }
}

export default new TurboHelper();
