import {injectGlobal} from "@emotion/css";
import ToggleWidgetAction from "./ToggleWidgetAction";
import trans from "../../../assets/helpers/translator";
import timesIcon from "../assets/times.svg";

class PageStructureAction {
    handlesAction() {
        return "page-structure";
    }

    isPersistent() {
        return false;
    }

    perform(details) {
        new ToggleWidgetAction().perform({ close: true });

        let structureHtmlContent = "";
        this.getPageStructureElements().forEach(element => {
            let paddingLeft = 22;
            if (element.hasAttribute("aria-level")) {
                paddingLeft *= element.getAttribute("aria-level");
            } else if (element.nodeName.toLowerCase().startsWith("h")) {
                let headingSize = element.nodeName.substring(1);
                paddingLeft *= headingSize;
            }

            let nodeType = element.nodeName;
            if (!nodeType.toLowerCase().startsWith("h")) {
                nodeType = "H";
                nodeType += element.getAttribute("aria-level") || "1";
            }

            structureHtmlContent += `
                <a 
                    href="#" 
                    class="wbb-structure-element" 
                    tabindex="0" 
                    data-offset-top="${element.offsetTop}" 
                    style="padding-left: ${paddingLeft}px;"
                >
                    <div class="wbb-structure-element-name">${nodeType}</div>
                    <div class="wbb-structure-element-text">${element.innerText}</div>
                </a>
            `;
        });

        document.querySelector("body").insertAdjacentHTML("afterbegin", `
            <div class="wbb-structure-modal-backdrop"></div>
            <div class="wbb-structure-modal">
                <div class="wbb-structure-modal-header">
                    <div class="wbb-structure-modal-header-title">
                        ${trans("Page structure")}
                    </div>
                    <div class="wbb-structure-modal-header-close" role="button" aria-label="${trans("Close page structure modal")}">
                        <img src="${timesIcon}" alt="${trans("Close")}" />
                    </div>
                </div>
                
                <div class="wbb-structure-modal-content">
                    ${structureHtmlContent}
                </div>
            </div>
        `);

        document.querySelector(".wbb-structure-modal").focus();

        this.handleCloseModal();
        this.handleStructureElementClick();
    }

    handleCloseModal() {
        document.querySelector(".wbb-structure-modal-header-close").addEventListener("click", () => {
            this.closeModal();
        });

        document.querySelector(".wbb-structure-modal-backdrop").addEventListener("click", () => {
            this.closeModal();
        });
    }

    closeModal() {
        document.querySelector(".wbb-structure-modal-backdrop").remove();
        document.querySelector(".wbb-structure-modal").remove();
    }

    handleStructureElementClick() {
        // TODO: Save structure elements to window and then access them by index to get element object so we can scroll to it and focus it (focus part is now missing!)
        document.querySelectorAll(".wbb-structure-element").forEach(element => {
            element.addEventListener("click", event => {
                event.preventDefault();
                this.closeModal();

                const offsetTop = parseInt(event.currentTarget.dataset.offsetTop);
                window.scrollTo({
                    top: offsetTop
                });
            });
        });
    }

    getPageStructureElements() {
        let structureElements = [];
        document.querySelectorAll("*").forEach((element) => {
            if (this.isHeading(element) && this.isVisible(element) && this.isHtmlElement(element)) {
                structureElements.push(element);
            }
        });

        return structureElements;
    }

    isHeading(element) {
        if (element.hasAttribute("role") && element.getAttribute("role") === "presentation") {
            return false;
        }

        const isElementHeading = /^h\d{1}$/gi.test(element.nodeName);
        const isElementRoleHeading = element.hasAttribute("role") && element.getAttribute("role") === "heading";

        return isElementHeading || isElementRoleHeading
    }

    isVisible(element) {
        return !this.isAriaHidden(element) && !this.isHiddenUsingStyles(element) && this.isVisibleOnDom(element);
    }

    isAriaHidden(element) {
        return element.hasAttribute("aria-hidden") && element.getAttribute("aria-hidden") === "true";
    }

    isHiddenUsingStyles(element) {
        return window.getComputedStyle(element)?.display === "none";
    }

    isVisibleOnDom(element) {
        return (element.offsetParent !== null);
    }

    isHtmlElement(element) {
        return !this.isTextNode(element) && !this.isCommentNode(element);
    }

    isTextNode(element) {
        return element.nodeType === Node.TEXT_NODE;
    }

    isCommentNode(element) {
        return element.nodeType === Node.COMMENT_NODE;
    }

    registerCustomStyles() {
        // language=scss
        injectGlobal`
            .wbb-structure-modal-backdrop {
                position: fixed;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
                background-color: rgb(0 0 0 / 0.2);
                z-index: 2147483646;
            }
            
            .wbb-structure-modal {
                all: revert;
                position: fixed;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);
                width: 100%;
                min-height: 80%;
                z-index: 2147483646;
                background-color: #fff;
                border: 1px solid var(--wbb-widget-borderColor);
                box-shadow: 0 10px 15px -3px rgb(0 0 0 / 0.1), 0 4px 6px -4px rgb(0 0 0 / 0.1);
                font-family: 'Inter', sans-serif;
                font-size: initial;
                font-weight: initial;
              
                @media (min-width: 1024px) {
                    width: 50%;
                    border-radius: 0.5rem;
                }
            }

            .wbb-structure-modal-header {
                all: revert;
                display: flex;
                justify-content: space-between;
                align-items: center;
                padding: 15px 20px;
                background-color: var(--wbb-widget-headerBackgroundColor);
                color: var(--wbb-widget-headerTextColor);

                @media (min-width: 1024px) {
                    border-top-left-radius: 0.3rem;
                    border-top-right-radius: 0.3rem;
                }
            }

            .wbb-structure-modal-header-title {
                all: revert;
                font-size: 18px;
                font-weight: 500;
                line-height: 18px;
            }

            .wbb-structure-modal-header-close {
                cursor: pointer;
                border-radius: 100%;
                padding: 5px;
                position: absolute; 
                right: 12px;

                img {
                    height: 22px;
                }
  
                &:hover {
                    background-color: rgb(0 0 0 / 0.1);
                }
            }
            
            .wbb-structure-modal-content {
                all: revert;
                overflow-x: auto;
                color: #000000;
                padding: 10px 0;
                max-height: 80vh;
            }
            
            .wbb-structure-element {
                all: revert;
                padding: 12px 22px 12px 0;
                display: flex;
                align-items: center;
                text-decoration: none !important;
                text-transform: none !important;
            }

            .wbb-structure-element:hover, 
            .wbb-structure-element:focus {
                background-color: #f5f5f5;
            }
            
            .wbb-structure-element-name {
                all: revert;
                text-decoration: none !important;
                color: var(--wbb-widget-headerTextColor);
                background-color: var(--wbb-widget-headerBackgroundColor);
                font-weight: 500;
                padding: 2px 10px;
                border-radius: 3px;
            }
            
            .wbb-structure-element-text {
                all: revert;
                padding-left: 15px;
                color: #006dd5 !important;
            }

            .wbb-structure-element:hover .wbb-structure-element-text, 
            .wbb-structure-element:focus .wbb-structure-element-text {
                text-decoration: underline !important;
                text-underline-offset: 3px !important;
            }
        `;
    }
}

export default PageStructureAction;
