import trans from "./translator";

export default {
    search: {
        placeholder: trans("Search..."),
    },
    sort: {
        sortAsc: trans("Sort column ascending"),
        sortDesc: trans("Sort column descending"),
    },
    pagination: {
        previous: trans("Previous"),
        next: trans("Next"),
        navigate: (page, pages) => trans("Page {page} of {pages}", {page, pages}),
        page: (page) => trans("Page {page}", {page}),
        showing: trans("Showing"),
        of: trans("of"),
        to: trans("to"),
        results: trans("results"),
    },
    loading: trans("Loading..."),
    noRecordsFound: trans("No matching records found"),
    error: trans("An error happened while fetching the data"),
};
