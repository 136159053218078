import axios from "axios";
import configGetter from "../config_getter";
import log from "../../../assets/helpers/logger";
import scriptDataGetter from "../script_data_getter";
import trans from "../../../assets/helpers/translator";
import tts from "../tts";
import {injectGlobal} from "@emotion/css";
import playIcon from "../assets/play-solid.svg";
import stopIcon from "../assets/stop-solid.svg";

class OcrPdfAction {
    isEnabledByDefault() {
        return true;
    }

    perform(details) {
        const userId = scriptDataGetter.get("userId");
        const widgetPdfOcrUrl = configGetter.get("widgetPdfOcrUrl").replace("{userId}", userId);

        axios.get(widgetPdfOcrUrl).then(this.handlePdfOcrResponse);
    }

    handlePdfOcrResponse(response) {
        const pdfLinksToReplaceWithOcrOnes = response.data;
        log.debug("Got PDF links to replace with OCR ones...", pdfLinksToReplaceWithOcrOnes);

        document.querySelectorAll("a[href*='.pdf']").forEach(link => {
            const absoluteLinkUrl = link.href;
            const currentLinkReplacements = pdfLinksToReplaceWithOcrOnes.filter(pdfOcrLink => {
                return pdfOcrLink.url === absoluteLinkUrl
            });

            let currentLinkReplacement = null;
            if (currentLinkReplacements) {
                currentLinkReplacement = currentLinkReplacements[0];
            }

            if (currentLinkReplacement === null) {
                return;
            }

            link.href = currentLinkReplacement.ocrPublicUrl;
            log.debug("Replacing PDF link with OCR PDF one!", absoluteLinkUrl, currentLinkReplacement.ocrPublicUrl);

            const pdfText = currentLinkReplacement.pdfText;
            if (pdfText === null) {
                return;
            }

            const linkFontSize = window.getComputedStyle(link).fontSize;
            link.insertAdjacentHTML("afterend", `
                <a 
                    href="#"
                    role="button" 
                    aria-label="${trans("Read this PDF")}" 
                    title="${trans("Read this PDF")}" 
                >
                    <img 
                        alt="${trans("Read this PDF")}" 
                        src="${playIcon}" 
                        class="wbb-read-pdf-icon" 
                        style="height: calc(${linkFontSize} + 2px);" 
                    />
                </a>
            `);

            document.querySelector(".wbb-read-pdf-icon")
                .parentNode
                .addEventListener("click", event => {
                    event.preventDefault();
                    let ttsButtonParent = event.target;
                    if (ttsButtonParent.tagName === "IMG") {
                        ttsButtonParent = ttsButtonParent.parentNode;
                    }
                    let ttsButtonIcon = ttsButtonParent.children[0];

                    if (ttsButtonIcon.src === undefined || ttsButtonIcon.src === playIcon) {
                        tts.speak(pdfText, true, 0.7);
                        ttsButtonIcon.src = stopIcon;
                        ttsButtonIcon.alt = trans("Stop PDF reading");

                        ttsButtonParent.title = trans("Stop PDF reading");
                        ttsButtonParent.ariaLabel = trans("Stop PDF reading");
                    } else {
                        tts.cancel();
                        ttsButtonIcon.src = playIcon;
                        ttsButtonIcon.alt = trans("Read this PDF");

                        ttsButtonParent.title = trans("Read this PDF");
                        ttsButtonParent.ariaLabel = trans("Read this PDF");
                    }
                });
        });
    }

    registerCustomStyles() {
        // language=scss
        injectGlobal`
            .wbb-read-pdf-icon {
                cursor: pointer;
                vertical-align: middle;
            }
        `;
    }
}

export default OcrPdfAction;
