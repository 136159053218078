import { injectGlobal } from "@emotion/css";
import classesApplier from "../classes_applier";
import tts from "../tts";
import trans from "../../../assets/helpers/translator";

class ContrastBlockAction {
    handlesAction() {
        return "block-toggle#ContrastBlock";
    }

    isPersistent() {
        return true;
    }

    perform(details) {
        const enable = details.enabled;
        const level = details.level;

        classesApplier.remove(
            "html",
            false,
            "wbb-contrast-1",
            "wbb-contrast-2",
            "wbb-contrast-3",
        );

        if (enable === false) {
            return;
        }

        classesApplier.apply("html", false, `wbb-contrast-${level}`);

        tts.speak(trans("{level}. level of contrast mode activated", {level}));
    }

    registerCustomStyles() {
        // language=scss
        injectGlobal`
            .wbb-contrast-1 {
                filter: invert(100%);
            }

            .wbb-contrast-2 *:not(a, iframe, img, #widget-opener, #widget-opener *) {
                transition: all 0s ease 0s;
                background-color: rgb(0, 0, 0) !important;
                border-color: rgb(255, 255, 255) !important;
                color: rgb(80, 208, 160) !important;
            }
            .wbb-contrast-2 a {
                transition: all 0s ease 0s;
                background-color: rgb(0, 0, 0) !important;
                border-color: rgb(255, 255, 255) !important;
                color: rgb(252, 255, 60) !important;
            }

            .wbb-contrast-3 *:not(a, iframe, img, #widget-opener, #widget-opener *) {
                transition: all 0s ease 0s;
                background-color: rgb(255, 255, 255) !important;
                border-color: rgb(0, 0, 0) !important;
                color: rgb(0, 0, 0) !important;
            }
            .wbb-contrast-2 a {
                transition: all 0s ease 0s;
                background-color: rgb(255, 255, 255) !important;
                border-color: rgb(0, 0, 0) !important;
                color: rgb(0, 0, 211) !important;
            }
        `;
    }
}

export default ContrastBlockAction;
