import settingsManager from "../settings_manager";

class ResetAllAction {
    handlesAction() {
        return "reset-all";
    }

    perform(details) {
        settingsManager.clearAll();
        window.location.href = "#wbb";
        location.reload();
    }
}

export default ResetAllAction;
