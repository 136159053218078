
export default {
  "Log out": () => "Odhlásiť sa",
  Register: () => "Registrácia",
  "Create an account and get 14 days for free!": () => "Vytvorte si účet a získajte 14 dní zadarmo!",
  "I agree to the <a target=\"_blank\" href=\"%url%\">terms of service</a>": () => "Súhlasím s <a target=\"_blank\" href=\"%url%\">podmienkami služby</a>",
  "Register now!": () => "Zaregistrovať!",
  "Already have an account? Log in.": () => "Už máte účet? Prihláste sa.",
  "Accessibility menu": () => "Prístupnosť",
  "Page structure": () => "Štruktúra stránky",
  "Reset all": () => "Resetovať všetko",
  Settings: () => "Nastavenia",
  "First setup": () => "Prvotné nastavenie",
  Login: () => "Prihlásenie",
  "Do you need to create a new account?": () => "Potrebujete vytvoriť nový účet?",
  Email: () => "Email",
  Password: () => "Heslo",
  "Phone number": () => "Telefónne číslo",
  "Remember me": () => "Zapamätať",
  "Log in!": () => "Prihlásiť sa!",
  "Forgot your password?": () => "Zabudnuté heslo?",
  "Add new website": () => "Pridať nový web",
  Provides: () => "Poskytuje",
  "Save details": () => "Uložiť údaje",
  Websites: () => "Weby",
  Account: () => "Účet",
  Invoices: () => "Faktúry",
  "Billing details": () => "Fakturačné údaje",
  Active: () => "Aktívny",
  "Non-active": () => "Neaktívny",
  Domain: () => "Doména",
  "Open in new tab": () => "Otvoriť v novej záložke",
  "Added at": () => "Dátum pridania",
  "Last activity": () => "Posledná aktivita",
  Edit: () => "Upraviť",
  "Search...": () => "Hľadať...",
  "Sort column ascending": () => "Zoradiť stĺpec vzostupne",
  "Sort column descending": () => "Zoradiť stĺpec zostupne",
  Previous: () => "Prechádzajúci",
  Next: () => "Ďalší",
  "Page {page} of {pages}": (d) => "Strana " + d.page + " z " + d.pages,
  "Page {page}": (d) => "Strana " + d.page,
  Showing: () => "Zobrazujem",
  of: () => "z",
  to: () => "až",
  results: () => "výsledkov",
  "Loading...": () => "Načítánie...",
  "No matching records found": () => "Neboli nájdené žiadne záznamy",
  "An error happened while fetching the data": () => "Pri načítaní údajov došlo k chybe",
  "Reset your password": () => "Obnoviť heslo",
  "Password reset": () => "Obnovenie hesla",
  "After submitting the form we will send you a link to reset your password": () => "Po odoslaní formulára Vám zašleme odkaz na resetovanie hesla",
  "Go back to login page": () => "Prejsť späť na prihlásenie",
  "Password reset email sent": () => "Email pre obnovenie hesla odoslaný",
  "If an account matching your email exists, then an email was just sent that contains a link that you can use to reset your password.": () => "Ak existuje účet zodpovedajúci zadanému e-mailu, bol práve odoslaný e-mail s odkazom na obnovenie hesla.",
  "This link will expire in": () => "Tento odkaz vyprší za",
  "If you don't receive an email please check your spam folder or <a href=\"{password_reset_link}\">try again</a>.": (d) => "Ak e-mail pre obnovu hesla nedostanete, skontrolujte prosím zložku so spamom alebo <a href=\"" + d.password_reset_link + "\">to skúste znova</a>.",
  "Please enter your new password": () => "Zadejte prosím nové heslo",
  "Send password reset email": () => "Odoslať email pre obnovu hesla",
  "Reset password": () => "Obnoviť heslo",
  "You have already requested a reset password email. Please check your email or try again soon.": () => "Už ste si vyžiadali e-mail so žiadosťou o obnovenie hesla. Skontrolujte prosím svoj e-mail alebo to neskôr skúste znova.",
  "New password": () => "Nové heslo",
  "Repeat password": () => "Nové heslo znova",
  "The password fields must match": () => "Heslá sa musia zhodovať",
  "Company dic": () => "DIČ",
  "Company country": () => "Krajina",
  "Company zip": () => "PSČ",
  "Company ic": () => "IČ",
  "Company city": () => "Mesto",
  "Company street": () => "Ulica",
  "Company name": () => "Názov spoločnosti",
  "Your changes were saved": () => "Vaše zmeny boli uložené",
  "Account settings": () => "Nastavenia účtu",
  "Save settings": () => "Uložiť nastavenia",
  "Your password has been changed successfully": () => "Vaše heslo bolo úspešne zmenené",
  "Change password": () => "Zmeniť heslo",
  "Current password": () => "Súčasné heslo",
  "Password change": () => "Zmena hesla",
  Firstname: () => "Meno",
  Surname: () => "Priezvisko",
  Save: () => "Uložiť",
  "Position on website": () => "Pozícia na webe",
  Theme: () => "Ofarbenie",
  "Bottom right": () => "Vpravo dole",
  "Bottom left": () => "Vľavo dole",
  "Top right": () => "Vpravo hore",
  "Top left": () => "Vľavo hore",
  "Widget settings": () => "Nastavenie widgetu",
  Revert: () => "Vrátiť späť",
  Copy: () => "Kopírovať",
  Continue: () => "Pokračovať",
  Copied: () => "Skopírované",
  "Opener Icon": () => "Ikonka otvárača",
  Person: () => "Osoba",
  Blind: () => "Nevidiaci",
  Deaf: () => "Hluchý",
  "Sign language": () => "Znaková reč",
  Wheelchair: () => "Invalidný vozík",
  "Search dictionary...": () => "Hľadať v slovníku...",
  "Big Cursor": () => "Veľký kurzor",
  Contrast: () => "Kontrast",
  "No results found": () => "Neboli nájdené žiadne výsledky",
  "Learn more on Wikipedia": () => "Zistiť viac na Wikipédii",
  "Stop animations": () => "Zastaviť animácie",
  "Font size": () => "Veľkosť písma",
  "Line height": () => "Výška riadka",
  "Text spacing": () => "Rozsadenie textu",
  Saturation: () => "Barevnost",
  Close: () => "Zavrieť",
  "Close page structure modal": () => "Zavrieť okno so štruktúrou stránky",
  Company: () => "Spoločnosť",
  Administration: () => "Administrácia",
  Users: () => "Užívatelia",
  "Highlight links": () => "Zvýrazniť odkazy",
  Focus: () => "Sústredenie",
  Impersonate: () => "Impersonovať",
  "Exit impersonation": () => "Zrušiť impersonáciu",
  "Screen reader": () => "Čítačka",
  "Click to help with orientation on this page": () => "Kliknite pre pomoc s orientáciou na tejto stránke",
  "Hide tooltip describing widget functionality": () => "Skryť titulok popisujúci funkčnosť widgetu",
  "Open help to orient yourself on this website": () => "Otvoriť pomocníka pre orientáciu na stránke",
  Views: () => "Zobrazení",
  "Preview PDF OCR": () => "Náhľad PDF OCR",
  "Original PDF": () => "Pôvodný PDF",
  "Modified PDF": () => "Modifikovaný PDF",
  "PDF file to convert": () => "Súbor PDF na konvertovanie",
  "Please upload a valid PDF document": () => "Prosím nahrajte platný PDF dokument",
  "Upload and show preview": () => "Nahrať a zobraziť náhľad",
  "Language of PDF": () => "Jazyk PDF",
  "Textual content": () => "Textový obsah"
}