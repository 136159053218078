import { injectGlobal } from "@emotion/css";
import tts from "../tts";
import trans from "../../../assets/helpers/translator";

class TtsBlockAction {
    handlesAction() {
        return "block-toggle#TtsBlock";
    }

    isPersistent() {
        return true;
    }

    handleTts(event) {
        const removeHighlights = () => {
            document.querySelectorAll(".wbb-tts-reading").forEach(element => {
                element.classList.remove("wbb-tts-reading");
            });
        }

        const mouseTarget = event.target;

        const ignoredElements = [ "b", "strong", "i", "em", "button", "input", "textarea", "select", "option", "label", "svg", "img", "canvas", "video", "audio", "hr", "a", "ul", "ol", "li", "dl", "dt", "dd", "tr", "td", "th", "tbody", "thead", "tfoot" ];

        if (window.currentElement === mouseTarget) {
            return;
        }

        if (ignoredElements.includes(mouseTarget.tagName.toLowerCase())) {
            window.currentElement = null;
            return;
        }

        removeHighlights();

        if (ignoredElements.includes(mouseTarget.nodeName.toLowerCase())) {
            return;
        }

        const childrenElements = [...mouseTarget.children].map(item => item.nodeName.toLowerCase());
        const blockElementsFound = ignoredElements.some(r => childrenElements.includes(r))
        if (mouseTarget.children.length !== 0 && !blockElementsFound) {
            return;
        }

        const textContent = mouseTarget.textContent.trim();
        if (textContent.length === 0) {
            return;
        }

        window.currentElement = mouseTarget;
        mouseTarget.classList.add("wbb-tts-reading");

        tts.speak(textContent);
    }

    perform(details) {
        const enable = details.enabled;
        const savedInit = details.savedInit || false;

        if (enable === false) {
            tts.cancel();
            document.removeEventListener("mousemove", this.handleTts);
            return;
        }

        document.addEventListener("mousemove", this.handleTts);

        if (savedInit === false) {
            tts.speak(trans("Reader enabled"), true);
        }
    }

    registerCustomStyles() {
        // language=scss
        injectGlobal`
            .wbb-tts-reading {
                background-color: #fff302 !important;
                color: #000000 !important;
            }
        `
    }
}

export default TtsBlockAction;
