const classesApplier = () => ({
    apply(elementsSelector, applyToIframe, ...classes) {
        document.querySelectorAll(elementsSelector).forEach(element => {
            element?.classList.add(...classes);
        });

        if (applyToIframe === false) {
            return;
        }

        const iframe = document.querySelector("#widget-iframe");
        iframe.contentWindow.postMessage({
            type: "add-class",
            elementsSelector,
            classes
        }, "*");
    },

    remove(elementsSelector, applyToIframe, ...classes) {
        document.querySelectorAll(elementsSelector).forEach(element => {
            element?.classList.remove(...classes);
        });

        if (applyToIframe === false) {
            return;
        }

        const iframe = document.querySelector("#widget-iframe");
        iframe.contentWindow.postMessage({
            type: "remove-class",
            elementsSelector,
            classes
        }, "*");
    }
});

export default classesApplier();
