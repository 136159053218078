import log from "../../assets/helpers/logger";
import settingsManager from "./settings_manager";
import ToggleWidgetAction from "./actions/ToggleWidgetAction";
import ResetAllAction from "./actions/ResetAllAction";
import CursorBlockAction from "./actions/CursorBlockAction";
import ContrastBlockAction from "./actions/ContrastBlockAction";
import PageStructureAction from "./actions/PageStructureAction";
import StopAnimationsBlockAction from "./actions/StopAnimationsBlockAction";
import TextSizeBlockAction from "./actions/TextSizeBlockAction";
import LineHeightBlockAction from "./actions/LineHeightBlockAction";
import TextSpacingBlockAction from "./actions/TextSpacingBlockAction";
import SaturationBlockAction from "./actions/SaturationBlockAction";
import HighlightLinksBlockAction from "./actions/HighlightLinksBlockAction";
import FocusBlockAction from "./actions/FocusBlockAction";
import CursorPositionAction from "./actions/CursorPositionAction";
import TtsBlockAction from "./actions/TtsBlockAction";
import OcrPdfAction from "./actions/OcrPdfAction";

const actions = [ // TODO: Obtain classes automatically from actions directory
    new ToggleWidgetAction(),
    new ResetAllAction(),
    new PageStructureAction(),
    new CursorBlockAction(),
    new ContrastBlockAction(),
    new StopAnimationsBlockAction(),
    new TextSizeBlockAction(),
    new LineHeightBlockAction(),
    new TextSpacingBlockAction(),
    new SaturationBlockAction(),
    new HighlightLinksBlockAction(),
    new FocusBlockAction(),
    new CursorPositionAction(),
    new TtsBlockAction(),
    new OcrPdfAction()
];

const actionsPerformer = () => ({
    setup() {
        this.listenForActions();
        this.registerAllCustomStyles();
        this.activateAllEnabledByDefaultActions();
    },

    listenForActions() {
        const iframe = document.querySelector("#widget-iframe");
        if (iframe === null) {
            log.error("No iframe with widget found!");
            return;
        }

        const iframeWindow = iframe.contentWindow;

        window.addEventListener("message", message => {
            if (message.source !== iframeWindow) {
                return;
            }

            const data = message.data;
            const type = data.type;
            const details = data.details;
            const action = this.getActionPerformer(type);

            action?.perform(details);

            if (details?.enabled === false) {
                settingsManager.remove(type, false);
                return;
            }

            if (typeof action?.isPersistent === "function" && action?.isPersistent()) {
                settingsManager.save(type, details);
            }
        });
    },

    getActionPerformer(type) {
        for (const index in actions) {
            const action = actions[index];
            if (typeof action?.handlesAction === "function" && action.handlesAction() === type) {
                return action;
            }
        }
        return null;
    },

    registerAllCustomStyles() {
        for (const index in actions) {
            const action = actions[index];

            if (typeof action?.registerCustomStyles === "function") {
                action.registerCustomStyles();
            }
        }
    },

    activateAllEnabledByDefaultActions() {
        for (const index in actions) {
            const action = actions[index];

            if (typeof action?.isEnabledByDefault === "function" && action.isEnabledByDefault() === true) {
                action.perform({
                    enabled: true
                });
            }
        }
    }
});

export default actionsPerformer();
