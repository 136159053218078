import { Controller } from "@hotwired/stimulus";
import moment from "moment";
import { Grid, html } from "gridjs";
import gridjsLocale from "../../assets/helpers/gridjs-locale";
import "../../assets/styles/gridjs.css";
import Routing from '../../public/bundles/fosjsrouting/js/router.min.js';
import trans from "../helpers/translator";
import * as Turbo from "@hotwired/turbo";

export default class extends Controller {
    connect() {
        this.renderTable();
    }

    renderTable() {
        if (this.element.querySelector('.gridjs.gridjs-container') !== null) {
            return;
        }

        const documentLang = document.documentElement.lang;

        const grid = new Grid({
            columns: [
                {
                    id: "domain",
                    name: trans("Domain"),
                    width: '30%',
                    formatter: (cell, row) => {
                        return html(`
                            <a 
                                href="http://${cell}"
                                target="_blank" 
                                title="${trans("Open in new tab")}"
                            >
                                ${cell}<i class="las la-external-link-alt pl-1"></i>
                            </a>
                        `);
                    }
                },
                {
                    id: "added-at",
                    name: trans("Added at"),
                    formatter: cell => moment(cell).locale(documentLang).format("LL"),
                    sort: {
                        compare: (a, b) => moment(a).diff(moment(b))
                    }
                },
                {
                    id: "last-activity",
                    name: trans("Last activity"),
                    formatter: cell => {
                        if (cell === null) {
                            return "-";
                        }

                        return moment(cell).locale(documentLang).fromNow();
                    },
                    sort: {
                        compare: (a, b) => moment(a).diff(moment(b))
                    }
                },
                {
                    id: "visits",
                    name: trans("Views trend (1M)"),
                    width: '20%',
                    sort: false,
                    formatter: data => {
                        const visitsHistoryKeys = Object.keys(data.visits_history || {})
                        if (visitsHistoryKeys.length < 7) {
                            return data.total_visits;
                        }

                        return html(`
                            <div class="h-6">
                                ${data.chart_html}
                            </div>
                        `);
                    }
                },
                {
                    id: "edit-url",
                    name: "",
                    sort: false,
                    width: '10%',
                    formatter: websiteId => {
                        const widgetEditUrl = Routing.generate('app_widget_edit', {
                            websiteId: websiteId
                        });

                        return html(`
                            <a 
                                href="${widgetEditUrl}"
                                class="button-xs unstyled-a !inline-block"
                            >
                                ${trans("Edit")}
                            </a>
                        `);
                    }
                }
            ],
            sort: true,
            pagination: {
                limit: 20,
                enabled: true
            },
            server: {
                url: Routing.generate('raw_websites_list'),
                then: data => data.map(website => [
                    website.domain,
                    website.added_at,
                    website.last_activity,
                    {
                        total_visits: website.total_visits,
                        visits_history: website.visits_history,
                        chart_html: website.chart_html
                    },
                    website.id
                ])
            },
            className: {
                pagination: "flex flex-col-reverse items-center justify-center md:block",
                paginationSummary: "mt-3 md:mt-[5px]",
                tr: 'cursor-pointer gridjs-hoverable-row',
            },
            language: gridjsLocale
        });

        grid.on('rowClick', (...args) => {
            const columns = args[1]['cells'];
            const websiteId = columns[columns.length - 1]['data'];
            const websiteEditUrl = Routing.generate('app_widget_edit', { websiteId });
            Turbo.visit(websiteEditUrl);
        });

        grid.render(this.element);
    }
}
