class ToggleWidgetAction {
    handlesAction() {
        return "toggle-widget";
    }

    isPersistent() {
        return false;
    }

    perform(details) {
        const widgetIframe = document.querySelector("#widget-iframe");
        const widgetVisible = window.getComputedStyle(widgetIframe).display !== "none";

        if (widgetVisible || details?.close) {
            widgetIframe.style.display = "none";
        } else {
            widgetIframe.style.display = "block";
            widgetIframe.focus();
        }
    }

    registerCustomStyles() {
        // language=scss
    }
}

export default ToggleWidgetAction;
