
export default {
  "Reader enabled": () => "Čtečka zapnuta",
  "Opening widget for better accessibility of this website": () => "Otevírám widget pro lepší orientaci na tomto webu",
  "{level}. level of contrast mode activated": (d) => d.level + ". stupeň kontrastního režimu byl aktivován",
  "{level}. level of saturation mode activated": (d) => d.level + ". stupeň sytosti byl aktivován",
  "Bigger cursor activated": () => "Větší kurzor byl aktivován",
  "Font size increased to {level}": (d) => "Velikost písma byla zvětšena na " + d.level,
  "Text spacing increased to {level}": (d) => "Rozestup písmen byl zvětšen na " + d.level,
  "Line height increased to {level}": (d) => "Výška řádku byla zvětšena na " + d.level,
  "Animations stopped": () => "Animace byly zastaveny",
  "Focus mode activated": () => "Režim soustředění byl aktivován",
  "All links were highlighted": () => "Všechny odkazy byly zvýrazněny",
  "Read this PDF": () => "Přečíst toto PDF",
  "Stop PDF reading": () => "Zastavit čtení PDF"
}