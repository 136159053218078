import { injectGlobal } from "@emotion/css";
import tts from "../tts";
import trans from "../../../assets/helpers/translator";

class TextSpacingBlockAction {
    handlesAction() {
        return "block-toggle#HighlightLinksBlock";
    }

    isPersistent() {
        return true;
    }

    perform(details) {
        const enable = details.enabled;
        const linkElements = document.querySelectorAll("a");

        linkElements.forEach(element => {
            element.classList.remove("wbb-highlight-links");
        });

        if (enable === false) {
            return;
        }

        linkElements.forEach(element => {
            element.classList.add("wbb-highlight-links");
        });

        tts.speak(trans("All links were highlighted"));
    }

    registerCustomStyles() {
        // language=scss
        injectGlobal`
            .wbb-highlight-links {
              text-decoration: underline !important;
              transition: all 0s ease 0s !important;
              color: rgb(255, 255, 0) !important;
              background-color: rgb(0, 0, 0) !important;
            }
        `;
    }
}

export default TextSpacingBlockAction;
