import FocusBlockAction from "./FocusBlockAction";

class CursorPositionAction {
    handlesAction() {
        return "cursor-position";
    }

    perform(details) {
        new FocusBlockAction().setFocusPosition(details.y);
    }
}

export default CursorPositionAction;
