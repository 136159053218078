import { Controller } from "@hotwired/stimulus";
import { Grid } from "gridjs";
import gridjsLocale from "../../assets/helpers/gridjs-locale";
import "../../assets/styles/gridjs.css";
import Routing from '../../public/bundles/fosjsrouting/js/router.min.js';
import trans from "../helpers/translator";
import * as Turbo from "@hotwired/turbo";

export default class extends Controller {
    connect() {
        this.renderTable();
    }

    renderTable() {
        if (this.element.querySelector('.gridjs.gridjs-container') !== null) {
            return;
        }

        const grid = new Grid({
            columns: [
                {
                    id: "id",
                    name: "#"
                },
                {
                    id: "email",
                    name: trans("Email"),
                },
                {
                    id: "firstname",
                    name: trans("Firstname"),
                },
                {
                    id: "surname",
                    name: trans("Surname"),
                },
                {
                    id: "companyName",
                    name: trans("Company"),
                },
            ],
            sort: true,
            search: true,
            pagination: {
                enabled: true,
                nextButton: false,
                prevButton: false,
                buttonsCount: 0,
                limit: 50
            },
            server: {
                url: Routing.generate('_admin_users'),
                then: data => data.map(user => [
                    user.id,
                    user.email,
                    user.firstname,
                    user.surname,
                    user.companyName,
                ])
            },
            className: {
                tr: 'cursor-pointer gridjs-hoverable-row',
            },
            language: gridjsLocale
        });

        grid.on('rowClick', (...args) => {
            const userId = args[1]['cells'][0]['data'];
            const userDetailUrl = Routing.generate('admin_user_detail', { userId });
            Turbo.visit(userDetailUrl);
        });

        grid.render(this.element);
    }
}
