import messages_cs from '../../translations/messages+intl-icu.cs.yaml';
import tts_cs from '../../translations/tts+intl-icu.cs.yaml';
import messages_sk from '../../translations/messages+intl-icu.sk.yaml';
import tts_sk from '../../translations/tts+intl-icu.sk.yaml';

// REFACTOR: Ability to switch language
const trans = (text, variables = {}) => {
    const browserLocale = getLocale();

    if (browserLocale === 'sk') {
        return getSlovakTranslation(text, variables) || text?.replace(/\{([^}]+)\}/g, (match, key) => variables[key] || match);
    }

    return getCzechTranslation(text, variables) || text?.replace(/\{([^}]+)\}/g, (match, key) => variables[key] || match);
}

const getLocale = () => {
    return navigator.language.slice(0, 2);
}

const getCzechTranslation = (text, variables = {}) => {
    if (text in messages_cs) {
        return messages_cs[text](variables);
    }

    if (text in tts_cs) {
        return tts_cs[text](variables);
    }

    return null;
};

const getSlovakTranslation = (text, variables = {}) => {
    if (text in messages_sk) {
        return messages_sk[text](variables);
    }

    if (text in tts_sk) {
        return tts_sk[text](variables);
    }

    return null;
};

export default trans;
