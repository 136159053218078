import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
    static targets = [
        "button",
        "content"
    ];

    connect() {
        this.registerButtonEvent();
    }

    registerButtonEvent() {
        this.buttonTarget.addEventListener('click', (event) => {
            event.preventDefault();
            this.copyToClipboard();
        });
    }

    copyToClipboard() {
        const originalButtonText = this.buttonTarget.innerText;
        navigator.clipboard.writeText(this.contentTarget.innerText).then(() => {
            this.buttonTarget.innerHTML = `<i class="las la-check"></i>`;

            setTimeout(() => {
                this.buttonTarget.innerText = originalButtonText;
            }, 1000);
        });
    }
}
