import settingsManager from "./settings_manager";
import TtsBlockAction from "./actions/TtsBlockAction";
import log from "../../assets/helpers/logger";
import getLocale from "../../assets/helpers/translator";

const tts = () => ({
    isEnabled() {
        return settingsManager.get(new TtsBlockAction().handlesAction()) !== null;
    },

    isSpeaking() {
        return window.speechSynthesis.speaking;
    },

    cancel() {
        window.speechSynthesis.cancel();
    },

    speak(text, force = false, speed = 1) {
        if (!this.isEnabled() && !force) {
            log.debug("TTS is disabled, ignoring speak request");
            return;
        }

        this.cancel();

        let utter = new SpeechSynthesisUtterance();
        utter.lang = getLocale() === 'sk' ? 'sk-SK' : 'cs-CZ'; // REFACTOR: Better way to do this thing
        utter.text = text;
        utter.volume = 1;
        utter.rate = speed;

        window.speechSynthesis.speak(utter);

        window.addEventListener('beforeunload', () => {
            if (this.isSpeaking()) {
                this.cancel();
            }
        });
    }
});

export default tts();
