import { injectGlobal } from "@emotion/css";
import elementUtils from "../element_utils";
import tts from "../tts";
import trans from "../../../assets/helpers/translator";

class TextSpacingBlockAction {
    handlesAction() {
        return "block-toggle#TextSpacingBlock";
    }

    isPersistent() {
        return true;
    }

    perform(details) {
        const enable = details.enabled;
        const level = details.level;

        elementUtils.getElementsWithText().forEach(element => {
            element.classList.remove("wbb-text-spacing-1", "wbb-text-spacing-2", "wbb-text-spacing-3");
        });

        if (enable === false) {
            return;
        }

        elementUtils.getElementsWithText().forEach(element => {
            element.classList.add(`wbb-text-spacing-${level}`);
        });

        tts.speak(trans("Text spacing increased to {level}", {level}));
    }

    registerCustomStyles() {
        // language=scss
        injectGlobal`
            .wbb-text-spacing-1 {
                word-spacing: 0.16em !important;
                letter-spacing: .12em !important;
            }
            .wbb-text-spacing-2 {
                word-spacing: 0.32em !important;
                letter-spacing: .24em !important;
            }
            .wbb-text-spacing-3 {
                word-spacing: 0.48em !important;
                letter-spacing: .36em !important;
            }
        `;
    }
}

export default TextSpacingBlockAction;
